import { useTranslation } from 'react-i18next';
import { Base, Section } from 'assets/styles/styled-components';
import { MediaSectionContainer, MediaList } from './styles';

export const MediaSection = () => {
    const { t } = useTranslation();
    const videoIdList:string[] = [
        '8JSjgFCIX6I',
        'WCypr_MCfvM',
        'a2RBTFJos6k',
        'KIzVlS27Pic',
        '-bZwd0eEeeQ',
        'q53XwXxTiZU'
    ]
    return (
        <Base>
            <Section>
                <MediaSectionContainer>
                    <h1 className='big-heading'>{t('MediaSectionTitle')}</h1>
                    <MediaList>
                        {
                            videoIdList.map((item:string)=>{
                                return (
                                    <iframe 
                                        src={`https://www.youtube.com/embed/${item}`}
                                        title='YouTube video player' 
                                        frameBorder='0' 
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' 
                                        allowFullScreen>
                                    </iframe>
                                )                     
                            })
                        }
                    </MediaList>
                </MediaSectionContainer>
            </Section>
        </Base>
    )
}