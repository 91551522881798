import styled from 'styled-components';

export const AboutSectionContainer = styled.article`
    .section-detailes{
        display: flex;
        flex-direction:column;
    }

    .button-link{
        width:100%
        margin-bottom:10px
    }
    @media only screen and (min-width: 1050px) {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 10px;
        align-items:center;
        text-align:start;
        
        .section-detailes{
            align-items:start;
        }
        .button-container{
            display: flex;
        }
        .button-link{
                width:auto;
                margin-right:10px
            }
    }

`

export const SquareImageList = styled.section`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    img{
        width:100%
    }
    .about-image-one, .about-image-two, 
    .about-image-three, .about-image-four{
        border-radius:15px;
    }
    .about-image-one{
        margin-bottom:10px;
        border-top-left-radius:75px;
    }

    .about-image-two{
        margin-bottom:7.5px;
        border-top-right-radius:   75px;
    }

    .about-image-three{
        border-bottom-right-radius:75px;
    }

    .about-image-four{
        border-bottom-left-radius: 75px;
    }

`