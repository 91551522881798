import styled from 'styled-components';

export const Base = styled.article`
    padding: 40px 0 50px;
    display:flex;
    justify-content:center;
`

export const Section = styled.section`
    width:90%;
    text-align:center;
    @media only screen and (min-width: 750px) {
        width:95%;
    }
`