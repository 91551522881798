import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Base, Section } from 'assets/styles/styled-components';
import { ProjectsContainer, ProjectCard } from './styles';

import { IProjectsDetail } from 'types';

export const ProjectsSection = () => {
    const { t } = useTranslation();
    const projectList:IProjectsDetail[] = [
        { image:`project-zsu-volunteers.png`, title:t('ProjectNameZSUVolunteers'), text:t('ProjectNameZSUVolunteersDescription'), alt:t('ProjectNameZSUVolunteersImageAlt')},
        { image:`project-children.png`, title:t('ProjectNameChildren'), text:t('ProjectNameChildrenDescription'), alt:t('ProjectNameChildrenImageAlt')},
        { image:`project-old-people.png`, title:t('ProjectNameElderlyPeople'), text:t('ProjectNameElderlyPeopleDescription'), alt:t('ProjectNameElderlyPeopleImageAlt')},
        { image:`project-displaced.png`, title:t('ProjectNameWarVictimsDisplacedPersons'), text:t('ProjectNameWarVictimsDisplacedPersonsDescription'), alt:t('ProjectNameWarVictimsDisplacedPersonsImageAlt')},
        { image:`project-churches.png`, title:t('ProjectNameChurchesAndMonasteries'), text:t('ProjectNameChurchesAndMonasteriesDescription'), alt:t('ProjectNameChurchesAndMonasteriesImageAlt')},
    ]
    return (
        <Base className='background-section'>
            <Section>
                <article>
                    <section className='about-projects-section'>
                        <h1 className='big-heading'>{t('MainPageProjectsSectionHeading')}</h1>
                        <p className='projects-section-description paragraph'>{t('MainPageProjectsSectionDescription')}</p>
                    </section>
                    <ProjectsContainer>
                    {
                        projectList.map(({image, title, text, alt}:IProjectsDetail)=>{
                            return (
                                <ProjectCard>
                                    <img className='w-100' src={`${process.env.PUBLIC_URL}/media/projects-images/${image}`} alt={alt} loading='lazy'/>
                                    <div className='p-4'>
                                        <h3>{title}</h3>
                                        <ReadMoreLess>{text}</ReadMoreLess>
                                        <NavLink to='/donate'>
                                            <Button className='btn-lg button-fill w-100'>
                                                <span className='w-100 text-center'>
                                                    {t('DonateButton')}
                                                </span>
                                            </Button>
                                        </NavLink>
                                    </div>
                                </ProjectCard>
                            )
                        })
                    }
                    <ProjectCard>
                        <img className='w-100' src={`${process.env.PUBLIC_URL}/media/africa.png`} loading='lazy'/>
                        <div className='p-4'>
                            <h3>International Dialogue</h3>
                            <p>
                                Obonato calls to join the Ukrainian-African dialogue. 
                                Non-governmental Charity Fund "Obonato" sees one of its 
                                strategic objectives .... 
                            </p>
                            <NavLink to='/international'>
                                <Button className='btn-lg button-fill w-100'>
                                    <span className='w-100 text-center'>Read more</span>
                                </Button>
                            </NavLink>
                        </div>
                    </ProjectCard>
                </ProjectsContainer>
                </article>
            </Section>
        </Base>
    )
}


const ReadMoreLess = ({children}:any) => {
    const { t } = useTranslation();
    const [isShownMore, setInShownMore] = useState(false)
    const toggleBtn = () => {
        setInShownMore(!isShownMore)
    }
    return (
        <p className='project-description'>
            {isShownMore ? children : `${children.substr(0, 200)}...` }
            <button className='bg-white btn-link p-1 border-0' onClick={toggleBtn}>
                {isShownMore ? t('ReadLessButton') : t('ReadMoreButton')}
            </button>
        </p>
    )
}