import common_en from './en/en.json';
import common_ua from './ua/ua.json';

export default {
    nsSeparator: false,
    keySeparator: false,
    en: {
        translation: common_en,
    },
    ua: {
        translation: common_ua,
    },
};