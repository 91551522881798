import '@material-ui/styles'
import {CurrencySlot} from './currencySlot'

export const DonateMethods = ({type}:any) => {
    const trnsf:any = [
        { key:'PDTxNre6jxd1C9KVaK6m3HZJ784fJk3n', name:"IBAN Code US Dollar (USD)",  value:"UA063052990000026000035032422"},
        { key:'7GWOlyVqFtG13pTDSfRX0vkxX42IY2Ai', name:"IBAN Code Euro (EUR)",  value:"UA133052990000026008035026980"},
        { key:'bInhWFeU9ZejATPfDm2q8tcI7wRg9JNZ', name:"IBAN Code Hryvnia (UAH)",  value:"UA613052990000026005015022587"}
    ]

    const crypto:any = [
        { key:'foScgpxn3c1ZwhED9ybGCQRDcTMSe9xJ', name:'Ethereum (ETH)',  value:'0x66aC8407664120Da77484c625502EBf13e499913'},
        { key:'eB6RyaQTbZNcmMRMkdrjDmBja5JprpsS', name:'Bitcoin (BTC)',  value:'bc1qz4aysuyq7479njupnfv46rpeaaeeh3z883d3he'},
        { key:'Pd34mywqErsA3POKByyEf9FegaX1beMj', name:'BNB Beacon Chain (BNB)',  value:'bnb1ujx4f3wf6qj6t0j2ktucc2cj7fz6k2gxd9c7d7'},
        { key:'R7fuflWRv1IRL1aiOqu6VMgzRf6idyZ1', name:'Litecoin (LTC)',  value:'ltc1qxds93gypz3k0fkxfgxmn8v5y8pygk7cm9kn4tl'},
        { key:'Ae9xH6adM00izkLvwSXOmOkrLdMprMZc', name:'Polygon (MATIC)',  value:'0x66aC8407664120Da77484c625502EBf13e499913'},
        { key:'bRgnpGPkYpZS7xKRLVIyC0vmDZ1rJied', name:'Tron (TRX)',  value:'TMMDJ46zBoVNxhzhadqzbrLUDqoppDnRaX'},
        { key:'CGxVRDb3EjDms0Z83b8ARkyzGxSMJelg', name:'Dash (DASH)',  value:'Xj3jEYKFfcqdahd9fYPoEidSqbEByAMgjd'},
        { key:'wuAFQpCyWOU7J3AZGmgtqw3t68E3kZXn', name:'Dogecoin (DOGE)',  value:'DTBom1W32kxuRBfoaNC1Q3FZ2M2BXhJCvL'},
        { key:'jPTTxc1l2s13eGYSeD4eGA4xoxoUrnIf', name:'Solana (SOL)',  value:'BrNxQqKJ6UoWoSVWMbiavg3h3FfsXQFDhmwhNz4WNXzr'},
        { key:'JrJ64N9tPOR5Q1cjJ7g4PLN4JXvNlOLA', name:'Tether (USDT) (ERC20)',  value:'0x66aC8407664120Da77484c625502EBf13e499913'},
        { key:'sCfaqP9yNzcFXW9fv2lmvJO6lkbdLzBE', name:'Tether (USDT) (TRC20)',  value:'TMMDJ46zBoVNxhzhadqzbrLUDqoppDnRaX'},
        { key:'6faufm6fhRQPG6wDIW1L7YebdS0QG6x1', name:'USD Coin (USDC) (ERC20)',  value:'0x66aC8407664120Da77484c625502EBf13e499913'},
        { key:'lvXNFPg60J4nPCUfQciNKJg6c4mkXMrD', name:'USD Coin (USDC) (TRC20)',  value:'TMMDJ46zBoVNxhzhadqzbrLUDqoppDnRaX'},
        { key:'Ze1oQDlBHhH6aEGpc9bKSbgNWG8FApHU', name:'SHIBA INU (SHIB) (ERC20)',  value:'0x66aC8407664120Da77484c625502EBf13e499913'}
    ]

    return (
        <article>
                {
                    type === 'trnsf' ? (
                        trnsf.map(({ name, value, text_id}:any)=>{
                            return (
                                <CurrencySlot name={name} value={value}/>
                            )
                        })
                    ):(
                        crypto.map(({ name, value, text_id}:any)=>{
                            return (
                                <CurrencySlot name={name} value={value}/>
                            )
                        })
                    )
                }
        </article>
    )
}