import styled from 'styled-components';

export const NavigationContainer = styled.header`
    z-index:1;
    width:100%;
    position:fixed;
    display:flex;
    flex-direction:column;
    align-items:center;
    background:white;
    box-shadow:0 0 5px black;
    .nav-list{
        display:none
    }
    .main-logo, .main-logo-main{
        height:55px
    }

    .main-logo-main{
        display: none;
    }

    .language-switch-container{
        display:flex;
        align-items:center;
        font-size:1.35rem;
        span{
            font-size:1.35rem;
        }
    }

    .language-icon{
        height:30px;
        margin: 0 15px 0 0;
        cursor:pointer;
        
    }

    a{
        text-decoration:none;
    }

    @media only screen and (min-width: 750px) {
        .main-logo{
            display:none;
        }
        .main-logo-main{
            display:block;
        }
        .links-list{
            display: flex;
            align-items:center;
        }
        .nav-list{
            margin: 0;
            display:flex;
            justify-content:space-between;
            align-items:center;
        }
        .nav-tab{
            margin-right:30px;
            font-size:1.25rem;
            cursor:pointer;
            font-family: 'Roboto', sans-serif;
            font-weight:500;
        }
        a{
            transition:.5s;
            text-decoration:none;
            color: #887f7f;
        }
        a:hover{
            text-decoration:none;
            color:#444545 ;
        }
    }
`