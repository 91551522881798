import styled from 'styled-components';

export const FooterSection = styled.article`
    color:white;
    padding: 40px 0 0;
    display:flex;
    justify-content:center;
    background-color:#0b0c15;
    .footer-section{
        display:flex;
    }
    .contact-container{
        display:flex;
    }
    .footer-section-info{
        display: flex;
        flex-direction:column;
    }
    .icon-container{
        min-width:60px;
        height:60px;
        border-radius: 3px;
        background-color:#6c757d;
        display: flex;
        justify-content:center;
        align-items:center;
    }
`

export const ContentContainer = styled.article`
    @media only screen and (min-width: 750px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        align-items:center;
    }
`