import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

import { FooterSection, ContentContainer } from './styles';
import { Section } from 'assets/styles/styled-components';

export const Footer = () => {
    const { t } = useTranslation()
    const contactsData = [
        {
            icon:faLocationDot,
            details:[t('FooterContactUsSectionAddress')]
        },
        {
            icon:faPhone,
            details:[`${t('FooterContactUsSectionOstap')} +380 67 445 5296`, `${t('FooterContactUsSectionNataliia')} +38093 949 5591/ +380 97 943 8838`, `${t('FooterContactUsSectionArtem')} +380 50 542 7248`]
        },
        {
            icon:faEnvelope,
            details:[t('FooterContactUsSectionEmail')]
        }
    ]

    return (
        <>
        <FooterSection>
            <Section>
                <ContentContainer>
                    <section className='mb-5'>
                        <img 
                            className='mb-3' 
                            src={`${process.env.PUBLIC_URL}/media/main-logo/obonato_logo_white.svg`} 
                            alt={t('ObonatoLogoAlt')}
                            loading='lazy' 
                        />
                        <p>{t('FooterAboutSectionDescription')}</p>
                    </section>
                    <section className='mb-5'>
                        <h3 className='white-heading'>{t('MainPageProjectsSectionHeading')}</h3>
                        <ul className='p-0'>
                            <li className='mb-3'>
                                <span>{t('FooterProjectsSectionZsuVolunteers')}</span>
                            </li>
                            <li className='mb-3'>
                                <span>{t('FooterProjectsSectionChildren')}</span>
                            </li>
                            <li className='mb-3'>
                                <span>{t('FooterProjectsSectionElderlyPersons')}</span>
                            </li>
                            <li className='mb-3'>
                                <span>{t('FooterProjectsSectionInternallyDisplacedPersons')}</span>
                            </li>
                            <li className='mb-3'>
                                <span>{t('FooterProjectsSectionChurches')}</span>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h3 className='white-heading'>{t('FooterContactUsSectionHeading')}</h3>
                        <ul className='p-0'>
                            {
                                contactsData.map(({icon, details})=>{
                                    return (
                                        <li className='contact-container mb-5'>
                                            <div className='icon-container'>
                                                <FontAwesomeIcon icon={icon} size='2x'/>
                                            </div>
                                            <div className='footer-section-info ms-4'>
                                                {
                                                    details.map((item:string)=>{
                                                        return <span className='secondary-text'>{item}</span>
                                                    })
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </section>
                </ContentContainer>
            </Section>
        </FooterSection>
        <section style={{background:'#031c35'}} className='text-center p-3'>
            <p style={{color:'white'}} className='h5 m-0 p-0'>{t('SelfCreditsFirstHalf')} <span style={{color: '#e25555'}}>&#9829;</span> {t('SelfCreditsOtherHalf')} <a style={{color:"#ff713d"}} href='https://andrewmedvedev.dev' target='_blank' rel='noopener noreferrer'>{t('SelfCreditsEnd')}</a></p>
        </section>
        </>
    )
}