import styled from 'styled-components';

export const CurrencyOption = styled.article`
    width:100%;
    margin:2.5rem auto 0;
    display:flex;
    flex-direction:column;
    align-items:center;
    .valueContainer{
        display: flex;
        flex-direction:column;
    }
    .currencyName{
        font-size:24px;
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important;
    }
    .currencyCode{
        width: 300px;
        width: auto;
        font-size:24px;
        font-weight:900;
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important;
        border: none;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none; /*remove the resize handle on the bottom right*/
    }
    .currencyCode:disabled{
        background:none;
    }
    .copy-btn{
        display: flex;
        align-items:center;
        font-size:20px;
        color:gray;
    }
    .copy-btn:hover{
        color:#27c1ce;
    }

    @media only screen and (min-width: 750px) {
        width:75%;
        text-align:start;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        .currencyCode{
        width: 500px;
    }
    }
`