// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Base, Section } from 'assets/styles/styled-components';
import { DonateContainer } from './styles';

// import setPayment from 'redux/actions/setPayment';

export const DonateSection = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <Base>
            <Section>
                <DonateContainer className='rounded border'>
                    <section className='description-container'>
                        <h1 className='big-heading'>{t('MainPageCallToActionHeading')}</h1>
                        <p className='paragraph'>{t('MainPageCallToActionDescription')}</p>
                    </section>
                    <Button 
                        className='btn donate-button btn-lg button-fill'
                        onClick={()=>{navigate('/donate')}}
                    >
                        <span className='w-100'>{t('DonateButton')}</span>
                    </Button>
                </DonateContainer>
            </Section>
        </Base>
    )
}