import styled from 'styled-components';

export const IntroSectionContainer = styled.article`
    @media only screen and (min-width: 1050px) {
       display: flex;
       flex-direction:row-reverse;
    }
`

export const ImageSection = styled.section`
    img{
        width:100%;
    }
    @media only screen and (min-width: 1050px) {
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:20px;
        width:50%;
    }

`

export const IntroInformaitonContainer = styled.section`
    .section-detailes{
        display: flex;
        flex-direction:column;
    }
    @media only screen and (min-width: 1050px) {
        width:50%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:start;
        .section-detailes{
            align-items:start;
        }
    }

`