import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { Base, Section } from 'assets/styles/styled-components';
import { TeamContainer, PersonCard } from './styles';
import Data from './teamList.json';

import { ITeamMember } from 'types';

export const OurTeamSection = () => {
    const { t } = useTranslation();
    return (
        <Base>
            <Section>
                <article>
                    <h1 className='big-heading mb-5'>{t('NameHeading')}</h1>
                    <TeamContainer>
                        {
                            Data.teamList.map(({photo, name, telegram, phone, linkedin, facebook, email}:ITeamMember)=>{
                                return (
                                    <PersonCard>
                                        <img className='photo-image w-100' src={`${process.env.PUBLIC_URL}/media/obonato-team/${photo}`} alt={t(`Name${name}PhotoAlt`)} loading='lazy'/>
                                        <h3 className='mt-3'>{t(`Name${name}`)}</h3>
                                        {telegram && <p className='paragraph'><a href={`https://telegram.me/${telegram}`} target='_blank' rel='noopener noreferrer'>@{telegram}</a></p>}
                                        <p className='paragraph'>{phone}</p>
                                        <div>
                                            {
                                                linkedin && (
                                                    <a href={linkedin} target='_blank' rel='noopener noreferrer' title={linkedin}>
                                                        <FontAwesomeIcon className='member-social-media-icon' icon={faLinkedinIn} size='2x'/>
                                                    </a>
                                                )
                                            }
                                            {
                                                email && (
                                                    <FontAwesomeIcon className='member-social-media-icon ms-4 me-4' icon={faEnvelope} size='2x'/>
                                                )
                                            }
                                            {
                                                facebook && (
                                                    <a href={facebook} target='_blank' rel='noopener noreferrer' title={facebook}>
                                                        <FontAwesomeIcon className='member-social-media-icon' icon={faFacebook} size='2x'/>
                                                    </a>
                                                )
                                            }
                                        </div>
                                </PersonCard>
                                )
                            })
                        }
                        <PersonCard className='d-flex flex-column'>
                            <img className='photo-image w-100 mb-4' src={`${process.env.PUBLIC_URL}/media/obonato-team/join_our_team.png`} alt={t(`Name${name}PhotoAlt`)} loading='lazy'/>
                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdqkJzKW07Biz9OOw7pCqrISbJB3OPWsHEzAJ4BMr-UEWVMDg/viewform' target='_blank' rel='noreferrer'>
                                <Button className='w-100 btn-lg button-fill text-center'>
                                    <span className='w-100'>{t('JoinOourTeamButton')}</span>
                                </Button>
                            </a>
                        </PersonCard>
                    </TeamContainer>
                </article>
            </Section>
        </Base>
    )
}