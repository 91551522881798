import styled from 'styled-components';

export const ProjectsContainer = styled.article`
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    @media only screen and (min-width: 950px) {

    }
`

export const ProjectCard = styled.article`
    margin:10px;
    background:white;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    overflow:hidden;
    box-shadow: 3px 2px 35px rgb(0 27 85 / 10%);
    @media only screen and (min-width: 950px) {
        width:400px;
    }
`

export const InternationalSection = styled.article`
    text-align:start;
    h1{
        text-align:center;
        font-size:3rem;
    }
    p{
        font-size:1.1rem;
    }
    @media only screen and (min-width: 950px) {
    }
`