import { Button } from 'react-bootstrap';
import { NavigationBar } from 'components/navigationBar';
import { useTranslation } from 'react-i18next'
import { Footer } from 'components/footer';
import { Base, Section } from 'assets/styles/styled-components';
import { DonateMethods } from 'components/donate-page/donate-methods';
import { useEffect, useState } from 'react';

export const PaymentPage = () => {
    const [type, setType] = useState('trnsf')
    const { t } = useTranslation()
    useEffect(()=>{
        window.scrollTo({
            top: 0,
        });
    },[])

    const donateLink:any = {
        en:process.env.REACT_APP_ENGLISH_PAYMENT_URL,
        ua:process.env.REACT_APP_UKRAINIAN_PAYMENT_URL
    }

    return (
        <>
            <NavigationBar/>
            <article className='content'>
            <Base>
                <Section>
                    <section>
                        <h1 className='donate-page-title big-heading'>{t('DonatePageTitle')}</h1>
                    </section>
                    <section className='switch-methods-container'>
                        <a href={donateLink[t('DonatePageDonateButtonLink')]} target='_blank' rel='noopener noreferrer'>
                            <Button className='donate-page-button btn-lg button-fill'>{t('DonatePageDonateButton')}</Button>
                        </a>
                        <Button onClick={()=>{setType('trnsf')}} className={`${type === 'trnsf' && 'active-button'} btn-lg button-fill`}>{t('DonatePageTransfersButton')}</Button>
                        <Button onClick={()=>{setType('crypt')}} className={`${type === 'crypt' && 'active-button'} btn-lg button-fill`}>{t('DonatePageCryptoCurrencyButton')}</Button>
                    </section>
                    <section>
                    {(() => {
                    switch (type) {
                        case 'trnsf':
                            return <DonateMethods type='trnsf'/>
                        case 'crypt':
                            return <DonateMethods type='crypt'/>
                        default:
                            return null;
                        }
                    })()}
                    </section>
                </Section>
            </Base>
            </article>
            <Footer/>
        </>
    )
}