import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'

import pdf from './Registry_Obonato.pdf';

import { Base, Section } from 'assets/styles/styled-components';
import { SquareImageList , AboutSectionContainer } from './styles';

export const AboutSection = () => {
    const { t } = useTranslation();
    return (
        <Base>
            <Section>
                <AboutSectionContainer>
                    <SquareImageList>
                        <div>
                            <img 
                                className='about-image-one' 
                                src={`${process.env.PUBLIC_URL}/media/main-page/about/old.jpg`} 
                                alt={t('MainPageAboutSectionImageOneAlt')}
                                loading='lazy'/>
                            <img 
                                className='about-image-four' 
                                src={`${process.env.PUBLIC_URL}/media/main-page/about/children.jpg`} 
                                alt={t('MainPageAboutSectionImageFourAlt')}
                                loading='lazy'/>
                        </div>
                        <div>
                            <img 
                                className='about-image-two' 
                                src={`${process.env.PUBLIC_URL}/media/main-page/about/displaced.jpg`} 
                                alt={t('MainPageAboutSectionImageTwoAlt')}
                                loading='lazy'/>
                            <img 
                                className='about-image-three' 
                                src={`${process.env.PUBLIC_URL}/media/main-page/about/church.jpg`} 
                                alt={t('MainPageAboutSectionImageThreeAlt')}
                                loading='lazy'/>
                        </div>
                    </SquareImageList>
                    <section className='section-detailes'>
                        <h3 className='color-text sub-heading'>{t('MainPageAboutSectionSubHeading')}</h3>
                        <h1 className='big-heading'>{t('MainPageAboutSectionHeading')}</h1>
                        <p className='paragraph'>{t('MainPageAboutSectionText')}</p>
                        <div className='button-container'>
                            <a className='about-link' href={pdf} target='_blank' rel='noreferrer'>
                                <Button className='button-link btn-lg button-fill text-center'>
                                    <span className='w-100'>{t('LearnMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                </Button>
                            </a>
                            <a className='about-link' href='https://docs.google.com/spreadsheets/d/1FmEJPNEIJJAgZ_SyF3XDdWdOEnSHH0SIJPqipZDqtUQ/edit?usp=sharing' target='_blank' rel='noreferrer'>
                                <Button className='button-link btn-lg button-fill text-center'>
                                    <span className='w-100'>{t('ReportingButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                </Button>
                            </a>
                        </div>
                    </section>
                </AboutSectionContainer>
            </Section>
        </Base>
    )
}