import { Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'

import { Section } from 'assets/styles/styled-components';
import { NavigationContainer } from './styles';
import { useState, useEffect } from 'react';

export const NavigationBar = () => {
    const navigate = useNavigate()
    const [language, setLanguage] = useState('en')
    const { i18n, t } = useTranslation()
    useEffect(()=>{
        i18n.changeLanguage(language)
    },[language])
    return (
        <NavigationContainer>
            {/* <section style={{background:'#27c1ce'}} className='w-100 d-flex justify-content-center p-3'>
                <div>
                    <span style={{border:'1px solid #444545', borderRadius:25, color:'#444545'}} className='p-1 ps-2 pe-2'>BETA</span><span className='ms-2 text-white'>{t('BetaNotification')}</span>
                </div>
            </section> */}
            <Section>
                <nav className='d-flex pt-3 pb-3 justify-content-between align-items-center'>
                    <section className='links-list'>
                        <NavLink to='/'>
                            <img 
                                className='main-logo'
                                src={`${process.env.PUBLIC_URL}/media/main-logo/obonato_logo_main.svg`}
                                alt={t('ObonatoLogoAlt')}
                                loading='lazy'
                            />
                            <img 
                                className='main-logo-main'
                                src={`${process.env.PUBLIC_URL}/media/main-logo/obonato_logo.svg`}
                                alt={t('ObonatoLogoAlt')}
                                loading='lazy'
                            />
                        </NavLink>
                        <ul className='nav-list ms-5'>
                            <li className='nav-tab'>
                                <Link onClick={()=>{navigate('/')}} className='text-center' to='about-section' smooth={true} offset={-90} duration={200} >
                                    {t('NavigationAboutTab')}
                                </Link>
                            </li>
                            <li className='nav-tab'>
                                <Link onClick={()=>{navigate('/')}} className='text-center' to='projects-section' smooth={true} offset={-90} duration={200}>
                                    {t('NavigationProjectsTab')}
                                </Link>
                            </li>
                            <li className='nav-tab'>
                                <Link onClick={()=>{navigate('/')}} className='text-center' to='our-team-section' smooth={true} offset={-90} duration={200}>
                                    {t('NavigationOurTeamTab')}
                                </Link>
                            </li>
                            {/* <li className='nav-tab'>
                                <Link onClick={()=>{navigate('/')}} className='text-center' to='test3' smooth={true} offset={-50} duration={200}>
                                    {t('NavigationContactsTab')}
                                </Link>
                            </li> */}
                        </ul>
                    </section>
                    <section className='language-switch-container'>
                        <div className='me-4'>
                            <button 
                                className='btn'
                                onClick={()=>{setLanguage('ua')}}
                            >
                                <span>UA</span>
                            </button>
                            |
                            <button 
                                className='btn'
                                onClick={()=>{setLanguage('en')}}
                            >
                                <span>EN</span>
                            </button>
                        </div>                        
                        <NavLink to='/donate'>
                            <Button className='btn-lg button-fill'>{t('DonateButton')}</Button>
                        </NavLink>
                    </section>
                </nav>
            </Section>
        </NavigationContainer>
    )
}