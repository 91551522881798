import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'

import { CurrencyOption } from './styles';


export const CurrencySlot = ({name, value, text_id}:any) => {
    function copyText(value:any) {
        navigator.clipboard.writeText(value)
     }
    return (
        <CurrencyOption key={value}>
            <div className='valueContainer'>
                <span className='currency name'>{name}</span>
                <textarea disabled className='currencyCode'>{value}</textarea>
            </div>
            <Popover placement='right' content={(<span>Copied</span>)} trigger='click'>
                <button 
                    aria-describedby={text_id}
                    className='btn copy-btn' 
                    style={{cursor:'pointer'}} 
                    onClick={()=>{copyText(value)}}
                >
                    <span>Copy</span>
                    <FontAwesomeIcon className='ms-2' icon={faClipboard} size='1x'/>
                </button>
            </Popover>
        </CurrencyOption>
    )
}