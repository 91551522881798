import styled from 'styled-components';

export const MediaSectionContainer = styled.article`
    h1{
        margin-bottom:48px
    }
`

export const MediaList = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    iframe{
        margin:5px;
        width:560px;
        height:315px
    }
`