import styled from 'styled-components';

// export const DonateContainer = styled.section`
//     padding: 50px 20px;
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: repeat(4, 1fr);
//     grid-column-gap: 10px;
//     grid-row-gap: 10px;
//     .input-form{
//         grid-area: 3 / 1 / 4 / 3;
//     }
//     .donate-button{
//         width:100%;
//         grid-area: 4 / 1 / 5 / 3;
//     }
//     @media only screen and (min-width: 1000px) {
//         grid-template-columns: repeat(6, 1fr);
//         grid-template-rows: 1fr;
//         .input-form{
//             grid-area: 1 / 5 / 2 / 6;
//         }
//         .donate-button{
//             grid-area: 1 / 6 / 2 / 7;
//         }
//     }
// `

export const DonateContainer = styled.article`
    padding: 30px 20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    .description-container{
        width:100%
    }
    .donate-button{
        padding: 1rem;
        font-size:2.5rem
    }
    @media only screen and (min-width: 1000px) {
        flex-direction:row;
        justify-content:space-around;
        .description-container{
            width:500px
        }
        .donate-button{
            padding: 2rem;
            font-size:3rem
        }
    }
`