import { Footer } from 'components/footer';
import { Element } from 'react-scroll'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from 'components/navigationBar';
import { IntroSection } from 'components/main-page/IntroSection';
import { AboutSection } from 'components/main-page/aboutSection';
import { ProjectsSection } from 'components/main-page/projectsSection';
import { OurTeamSection } from 'components/main-page/ourTeamSection';
import { DonateSection } from 'components/main-page/donateSection';
import { MediaSection } from 'components/main-page/mediaSection';
import { Base, Section } from 'assets/styles/styled-components';
import pdf from './MOD_Ukraine.pdf';

import pdfFile1 from './pdfs/1.pdf';
import pdfFile2 from './pdfs/2.pdf';
import pdfFile3 from './pdfs/3.pdf';
import pdfFile4 from './pdfs/4.pdf';
import pdfFile5 from './pdfs/5.pdf';
import pdfFile6 from './pdfs/6.pdf';
import pdfFile7 from './pdfs/7.pdf';
import pdfFile8 from './pdfs/8.pdf';
import pdfFile9 from './pdfs/9.pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'

export const MainPage = () => {
    const { t } = useTranslation();
    return (
        <>
                <NavigationBar/>
                <article className='content'>
                    <IntroSection/>
                    <Element name='about-section'>
                        <AboutSection/>
                    </Element>
                    <Element name='projects-section'>
                        <ProjectsSection/>
                    </Element>
                    <Element name='our-team-section'>
                        <OurTeamSection/>
                    </Element>
                    <Element name=''>
                        <DonateSection/>
                    </Element>
                    <Element name=''>
                        <MediaSection/>
                    </Element>
                    <Base>
                        <Section>
                            <h1 className='big-heading'>{t('RequestsTitle')}</h1>
                            <article className='request-container'>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdf} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/MOD_Ukraine_Preview.png`} alt='' />
                                    </a>
                                    <a className='' href={pdf} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>

                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile1} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/1.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile1} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile2} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/2.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile2} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile3} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/3.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile3} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>

                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile4} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/4.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile4} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile5} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/5.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile5} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile6} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/6.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile6} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>

                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile7} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/6.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile7} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile8} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/8.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile8} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                                <section className='request-box'>
                                    {/* <h3>International Dialogue</h3> */}
                                    <a className='' href={pdfFile9} target='_blank' rel='noreferrer'>
                                    <img className='mb-3' src={`${process.env.PUBLIC_URL}/media/pdfs/9.jpg`} alt='' />
                                    </a>
                                    <a className='' href={pdfFile9} target='_blank' rel='noreferrer'>
                                        <Button className='w-100 button-link btn-lg button-fill text-center'>
                                            <span className='w-100'>{t('ReadMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                                        </Button>
                                    </a>
                                </section>
                            </article>
                        </Section>
                    </Base>
                </article>
            <Footer/>
        </>
    )
}