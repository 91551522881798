import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react';
import { NavigationContainer } from './navigationStyles';
import {Base, Section } from 'assets/styles/styled-components';
import { Button } from 'react-bootstrap';
import { InternationalSection } from './styles';

export const InternationalProject = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
        });
    },[])
    return (
        <>
        <InternationalProjectNavigation/>
        <article className='content'>
            <Base>
                <Section>
                    <InternationalSection>
                        <h1>International Dialogue</h1>
                        <p>Obonato calls to join the Ukrainian-African dialogue.</p>
                        <p>
                            Non-governmental Charity Fund "Obonato" sees one of its 
                            strategic objectives to initiate and grow the development 
                            of relations between Ukraine and African countries. 
                            The current situation demonstrates that one of the key 
                            problems that equally affects the interests of all is 
                            the supply of food from Ukraine to African, Arab countries and Asia.
                        </p>
                        <p>
                            When it comes to the well-being and life of entire nations, 
                            one cannot reduce the discussion of such problems only to economic issues. 
                            And we are confident that the solution to the problem of food security 
                            of African countries and ensuring the freedom of trade of Ukraine are 
                            mutually connected and will remain so for the next decades.
                        </p>
                        <p>
                            We are aware that many countries of the African continent have invested 
                            a lot of effort in developing relations with the Russian Federation, and, 
                            following the principle of realpolitik and their national interests, 
                            are in no hurry to join the diplomatic trend existing in the EU and the USA.
                        </p>
                        <p>
                            For obvious reasons, the leadership of Ukraine, 
                            which leads the defense of its country from the armed aggression of the Russian Federation, 
                            has no right to express a restrained position towards the aggressor country. 
                            This is an obvious obstacle to the development of Ukrainian-African relations, 
                            the growth of tension between our countries and peoples, 
                            which today is not only unacceptable but also mortally dangerous.
                        </p>
                        <p>
                            This is exacerbated by the rise of ultra-nationalist and racist sentiment, 
                            which we strongly condemn. Between our countries there is a huge demand for interethnic, 
                            intercultural dialogue, which is especially important in the era of virtual communication.
                        </p>
                        <p>
                            Such a direct dialogue will make it possible to demonstrate mutual warm and 
                            friendly feelings between the people of our countries, without prejudice to 
                            the national interests of all participants.
                        </p>
                        <p>
                            We extend to you the hand of friendship for the sake of the great future of our countries.
                        </p>
                        <p>
                            In the near future, we are planning a number of events aimed at developing 
                            a dialogue between the peoples of our countries and invite all interested 
                            parties to contact us in order to share their vision of such cooperation.
                        </p>
                        <p>You can contact us by writing to the e-mail ob@obonato.org.ua</p>
                        <p>Or in the messenger WhatsUp/ Telegram/ Viber by telephone number +38 067 402 6194</p>
                    </InternationalSection>
                </Section>
            </Base>
        </article>
        </>
    )
}

export const InternationalProjectNavigation = () => {
    const [language, setLanguage] = useState('en')
    const { i18n, t } = useTranslation()
    useEffect(()=>{
        i18n.changeLanguage(language)
    },[language])
    return (<NavigationContainer>
        {/* <section style={{background:'#27c1ce'}} className='w-100 d-flex justify-content-center p-3'>
            <div>
                <span style={{border:'1px solid #444545', borderRadius:25, color:'#444545'}} className='p-1 ps-2 pe-2'>BETA</span><span className='ms-2 text-white'>{t('BetaNotification')}</span>
            </div>
        </section> */}
        <Section>
            <nav className='d-flex pt-3 pb-3 justify-content-between align-items-center'>
                <section className='links-list'>
                    <NavLink to='/'>
                        <img 
                            className='main-logo'
                            src={`${process.env.PUBLIC_URL}/media/main-logo/obonato_logo_main.svg`}
                            alt={t('ObonatoLogoAlt')}
                            loading='lazy'
                        />
                        <img 
                            className='main-logo-main'
                            src={`${process.env.PUBLIC_URL}/media/main-logo/obonato_logo.svg`}
                            alt={t('ObonatoLogoAlt')}
                            loading='lazy'
                        />
                    </NavLink>
                </section>
                <section className='language-switch-container'>
                    <div className='me-4'>
                        <button 
                            className='btn'
                            onClick={()=>{setLanguage('ua')}}
                        >
                            <span>UA</span>
                        </button>
                        |
                        <button 
                            className='btn'
                            onClick={()=>{setLanguage('en')}}
                        >
                            <span>EN</span>
                        </button>
                    </div>                        
                    <NavLink to='/donate'>
                        <Button className='btn-lg button-fill'>{t('DonateButton')}</Button>
                    </NavLink>
                </section>
            </nav>
        </Section>
    </NavigationContainer>)
}