import { NavigationBar } from 'components/navigationBar';
import { Routes, Route } from 'react-router-dom';

import { MainPage } from 'pages';
import { PaymentPage } from 'pages/donate';
import { InternationalProject } from 'components/main-page/projectsSection/international';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/compiled/index.css';
import 'antd/dist/antd.css';

export const App = () => {
  return (
    <main className='App'>
        <Routes>
          <Route path='/' element={<MainPage/>}/>
          <Route path='/donate' element={<PaymentPage/>}/>
          <Route path='/international' element={<InternationalProject/>}/>
          <Route path='*' element={<MainPage/>}/>
        </Routes>
    </main>
  );
}

export default App;
