import styled from 'styled-components';

export const TeamContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    @media only screen and (min-width: 750px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }
`

export const PersonCard = styled.div`

    .photo-image{
        clip-path: circle(50% at 50% 50%);
    }
    .team-member-name{
        font-family: 'Saira', sans-serif;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }
    .member-social-media-icon{
        transition:.5s;
        color:#887f7f;
    }

    .member-social-media-icon:hover{
        text-decoration:none;
        color:#444545 ;
    }
    @media only screen and (min-width: 950px) {
    }
`