import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'

import { Base, Section } from 'assets/styles/styled-components';
import { ImageSection, IntroInformaitonContainer , IntroSectionContainer } from './styles';

export const IntroSection = () => {
    const { t } = useTranslation();
    return (
        <Base className='background-section'>
            <Section>
                <IntroSectionContainer>
                    <ImageSection>
                        <img src={`${process.env.PUBLIC_URL}/media/main-page/Outline_of_Ukraine.svg`} alt='Outline of Ukraine territory in blue and yellow colors' />
                    </ImageSection>
                    <IntroInformaitonContainer>
                        <section className='section-detailes'>
                            <h3 className='color-text  sub-heading'>{t('MainPageIntroSectionSubHeading')}</h3>
                            <h1 className='big-heading'>{t('MainPageIntroSectionHeading')}</h1>
                            <p className='paragraph'>{t('MainPageIntroSectionText')}</p>
                            {/* <Button className='btn-lg button-fill text-center'>
                                <span className='w-100'>{t('LearnMoreButton')}<FontAwesomeIcon className='ms-3' icon={faCircleRight} size='1x'/></span>
                            </Button> */}
                        </section>
                    </IntroInformaitonContainer>
                </IntroSectionContainer>
            </Section>
        </Base>
    )
}